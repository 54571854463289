/* Sticker component styles */
.stickerDiv {
  width: 4.4rem;
  min-width: fit-content;
  margin: 0.3rem 0 0 0;
  padding: 2.5px;
  background-color: #f5f5f5;
  border-radius: 25px;
  transform: rotate(2deg);
}
.sticker {
  background: #f5f5f5;
  border-radius: 25px;
  border-style: solid;
  border-color: #0a0a0a;
  border-width: 3px;
  padding: 2px;
}
.text {
  font-weight: bolder;
  color: #000000;
  font-family: 'Acme', sans-serif;
  font-size: 0.9rem;
}

.stickerDiv.contactClass {
  margin: 0.2rem 0.3rem;
  padding: 0.3rem;
}

@media (min-height: 700px) {
  .stickerDiv.contactClass {
    margin: 0.7rem 0.5rem;
    margin: calc(0.5rem + 1vh) calc(0.3rem + 1vw);
  }
}
@media (min-width: 1200px) {
  .stickerDiv.contactClass {
    margin: 1rem;
    margin: calc(0.4rem + 1vh) calc(0.8rem + 1vw);
    width: 5rem;
  }
  .sticker.contactClass {
    padding: 0.1rem 1.2rem;
  }

}