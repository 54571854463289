/* Title component styles */

.titleDiv {
  width: 60%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem 0.2rem 0.3rem 1rem;
  font-family: 'Special Elite', serif;
  color: #1d1209;
  z-index: 0;
}
.titleText {
  font-size: 2.2rem;
  margin: 0;
}
.titleTextLineTwo {
  margin: -0.3rem 0 0 0;
  font-size: 2.2rem;
  align-self: flex-end;
}
.doubleLined {
  max-width: 180px;
  min-width: 130px;
  max-height: 60px;
  margin: 1.5rem 0 0 1.5rem;
}
@media (min-width: 350px) {
  .titleDiv {
    margin: 2rem 0.2rem 0.3rem 1rem;
  }
  .doubleLined {
    max-width: 13rem;
    max-height: 4.3rem;
    margin: 1.5rem 0 0 1.5rem;
  }
  .titleText {
    font-size: 2.5rem;
  }
  .titleTextLineTwo {
    font-size: 2.5rem;
  }
}
@media (min-width: 750px) {
  .titleDiv {
    max-width: 600px;
    max-height: 200px;
    margin: 2rem 0.2rem 0.3rem 2rem;
  }
  .doubleLined {
    max-width: 16rem;
    max-height: 4.3rem;
    margin: 1.5rem 0 0 1.5rem;
  }
  .titleText {
    font-size: 3rem;
  }
  .titleTextLineTwo {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {

  .titleText {
    font-size: 3.5rem;
  }
  .titleTextLineTwo {
    font-size: 3.5rem;
  }
  .doubleLined {
    max-width: 20rem;
    max-height: 6rem;
    margin: 1.5rem 0 0 3rem;
  }
}
