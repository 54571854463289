.resumeLinks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}
.resumeLink:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.resumeLink {
  margin: 0.5rem;
}
