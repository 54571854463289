.mainAboutDiv {
  width: 100%;
  margin: 1rem 0 0 0;
}
.aboutPaperDiv {
  margin: 0 auto;
  max-width: 48rem;
}
.paperContent {
  width: 100%;
  height: auto;
  position: relative;
}
.paperHeader {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.aboutPaperTitle {
  font-family: 'Dancing Script', cursive;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: left;
  overflow: visible;
  margin: -0.5rem 0 0.7rem 1rem;
}
.paperMainContent {
  display: flex;
  flex-flow: column nowrap;
}
.aboutPaperText {
  font-family: 'Dancing Script', cursive;
  font-size: 1.22rem;
  margin: 0.7rem 2.3rem 1.8rem 2.3rem;
  line-height: 1.4rem;
  text-align: center;
}
.bottomFrameAndDate {
  font-family: 'Dancing Script', cursive;
  font-size: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 4rem 0 0;
}
.bottomFrameAndDate p {
  margin: 0 0 2.15rem 0;
}
