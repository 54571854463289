.resumeSkillsDiv {
  margin: 1rem auto 0.8rem auto;
  display: grid;
  grid-template-columns: auto;
  justify-content: start;
  justify-items: center;
  align-items: start;
  align-content: start;
}
.resumeSkillsSectionDiv {
  width: 100%;
  margin: 0.8rem auto;
}
.skillsSectionTitleDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem auto;
}
.skillsDiv {
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-items: center;
}

@media (min-width: 600px) {
  .resumeSkillsDiv {
    column-gap: 1rem;
  }
  .resumeSkillsSectionDiv {
    width: 100%;
    margin: 0.3rem auto;
  }
  .code {
    grid-column: 1;
    grid-row: 1 / 3;
    align-self: start;
  }
  .tools {
    grid-column: 2;
    grid-row: 1;
  }
  .adeptness {
    grid-column: 2;
    grid-row: 2;
  }
  .languages {
    grid-column: 2;
    grid-row: 3;
  }
  .softSkills {
    grid-column: 1;
    grid-row: 3;
  }
}
@media (min-aspect-ratio: 3/2) and (max-height: 800px) and (min-width: 500px) {
  .resumeSkillsDiv {
    margin: 0rem auto 0.2rem auto;
    column-gap: 0.1rem;
    grid-template-columns: auto;
  }
  .code {
    grid-column: 1;
    grid-row: 1/3;
  }
  .tools {
    grid-column: 2;
    grid-row: 1;
  }
  .adeptness {
    grid-column: 2;
    grid-row: 2;
  }
  .languages {
    grid-column: 3;
    grid-row: 2;
  }
  .softSkills {
    grid-column: 3;
    grid-row: 1;
  }
}

@media (min-width: 1200px){
  .resumeSkillsDiv {
    column-gap: 1.5rem;
    grid-template-columns: auto;
  }
  .code {
    grid-column: 1;
    grid-row: 1/3;
  }
  .tools {
    grid-column: 2;
    grid-row: 1;
  }
  .adeptness {
    grid-column: 2;
    grid-row: 2;
  }
  .languages {
    grid-column: 3;
    grid-row: 2;
  }
  .softSkills {
    grid-column: 3;
    grid-row: 1;
  }
}
@media (min-width: 1400px) {
  .resumeSkillsDiv {
    column-gap: calc(1.5rem + 1vw);
    margin: 0 auto;
  }
}
@media (min-width: 2000px) {
  .resumeSkillsDiv {
    column-gap: 5rem;
    margin: 0 auto;
  }
}
