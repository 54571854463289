.navBar {
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  right: 0.8rem;
  top: 0.4rem;
  z-index: 2;
}