.resumeEducationDivContent {
  width: 90%;
  height: 100%;
}
.resumeList {
  list-style: none;
  padding: 0;
  margin: 0.5rem 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.resumeList li {
  position: relative;
  width: 100%;
  margin: 0.25rem;
  max-width: 250px;
  padding: 0;
  font-size: 1rem;
}
.resumeList li p {
  margin: 0 0.2rem;
  line-height: 1.2rem;
  font-size: calc(0.8rem + 0.1vmin);
  line-height: calc(1rem + 0.1vmin);
}
@media (min-width: 1200px) {
  .resumeList {
    width: 100%;
    margin: 0.5rem auto;
  }
  .resumeList li {
    margin: 0.1rem;
    max-width: 300px;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: fill;
  }
}