section {
  width: 95%;
  height: 100%;
  grid-row: 2;
}
.mainSectionDiv {
  width: 100%;
  margin: 0px;
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
}
.resumeContentDiv {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}


@media (min-width: 900px) {
  .mainSectionDiv {
    grid-template-rows: 100%;
    grid-template-columns: 13% 90%;
    column-gap: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .mainSectionDiv {
    column-gap: 0.7rem;
  }
}