.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto-fill;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}
@media(min-width: 1200px) {
  .container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 15vh 85vh;
  }
}
