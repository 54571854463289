.polaroidDiv {
  width: 60%;
  max-width: 300px;
  height: auto;
  margin: 1rem;
  transform: rotate(1deg);
  position: relative;
}
.polaroidLink {
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  cursor: url(../../img/red-pin.png), pointer;

}
.pinDiv {
  position: absolute;
  margin: -0.1rem 0.4rem 0.14rem 0.71rem;
  z-index: 1;
}
.polaroid {
  width: 100%;
  height: auto;
  z-index: -1;
}
.polaroidDiv:hover {
  transform: scale(1.1);
  z-index: 1;
}
.polaroidShadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 103%;
  height: 103%;
  opacity: 0.8;
  background: linear-gradient(to top, black 30%, transparent);
  z-index: -2;
  filter: blur(2px);
}
@media (min-width: 650px) {
  .polaroidDiv {
    width: 45%;
    margin: 1rem 0.8rem;
  }
}
@media (min-width: 1200px) {
  .polaroidDiv {
    width: 13%;
    max-width: 200px;
    width: calc(12% + 1vmin);
    height: auto;
    margin: 0.5rem;
    transform: rotate(1deg);
    position: relative;
  }
  .polaroidDiv:hover {
    transform: scale(1.2);
  }
  .pinDiv {
    margin: -0.15rem 0.4rem 0rem auto;
  }
}