.navNotesDiv {
  margin: 0 0.5rem 0 0;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  width: 100%;
  z-index: 2;
}
.modalDiv {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
}