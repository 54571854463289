.textDiv {
  margin: 0.8rem auto;
  max-width: 80%;
}
.text {
  color: #1d1209;
  font-family: 'Special Elite', serif;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin: 0rem auto;
  text-shadow: 0.4px 0.4px 0px black;
  letter-spacing: 2px;
  font-weight: bold;
}
@media (min-height: 700px) {
  .textDiv {
    margin: 1.2rem auto;
    margin: calc(1rem + 1vh) auto;
  }
  .text {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
@media (min-width: 1200px) {
  .textDiv {
    margin: 1rem auto;
    margin: calc(0.8rem + 1vh) auto;
  }
  .text {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}