html {
  font-size: 14px;
  font-size: calc(14px + 6 * ((100vw - 280px) / 653));
  margin: 0 auto;
  width: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  font-family: 'Lato', sans-serif;
  background-color: #B27848;
  background-image: url('./../img/background-texture2.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  margin: 0px;
}

header {
  z-index: 1;
}

section {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
}
h1,
h2,
h3,
h4,
h5,
p,
div {
  margin: 0;
  margin-block: 0;
  text-align: center;
  padding: 0;
  font-size: 1rem;
}
ul,
li,
a,
input,
textarea,
button {
  list-style: none;
  margin: 0px;
  outline: none;
  text-decoration: none;
  border: none;
  color: #2b2b2b;
}
h3 {
  font-weight: 800;
  font-size: 1.5rem;
  margin: 0px auto 30px auto;
  width: 100%;
}
img {
  width: 100%;
  height: auto;
  margin: 0px;
}
figure {
  width: 100%;
  height: auto;
  margin: 0px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
.fa {
  color: #ffffff;
  border-radius: 50%;
  padding: 0.5rem;
  width: 1.801rem;
  height: 1.801rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.8rem;
  margin: 0.4rem;
}
.fa:hover {
  transform: scale(1.2);
}
.fa-facebook {
  background-color: #55a8e7;
}
.fa-linkedin {
  background-color: #0966c2;
}
.fa-instagram {
  background-color: #f1891f;
}
.fa-twitter {
  background-color: #1da0f2;
}
.fa-envelope {
  background-color: rgb(191, 32, 101);
}
.fa-github {
  background-color: #010101;
}
.fa-whatsapp {
  background-color: #01e676;
}
.fa-globe {
  background-color: #ffffff;
  color: #1683dc;
  padding: 0 0 0 0;
  width: 1.3rem;
  height: 1.3rem;
  font-size: 1.3rem;
  margin: 0rem;
}

@media (min-width: 320px) {
  html {
    font-size: 16px;
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }
}
@media (min-width: 600px) {
  html {
    font-size: 18px;
    font-size: calc(18px + 6 * ((100vw - 600px) / 850));
  }
  section {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 22px;
    font-size: calc(22px + 6 * ((100vw - 1200px) / 900));
  }
  section {
    width: 75%;
  }
  .fa {
    border-radius: 50%;
    padding: 0.65rem;
    width: 2.001rem;
    height: 2.001rem;
    font-size: 2rem;
    margin: 0.5rem;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 24px;
    font-size: calc(24px + 6 * ((100vw - 1600px) / 900));
  }
}
@media (min-width: 2000px) {
  html {
    font-size: 26px;
    font-size: calc(26px + 6 * ((100vw - 2000px) / 1000));
  }
}
@media (min-width: 3000px) {
  html {
    font-size: 30px;
  }
}
@media (orientation: landscape) and (max-height: 500px) {
  html {
    font-size: 14px;
    font-size: calc(14px + 1 * ((100vw - 900px) / 320));
  }
}
