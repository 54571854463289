/* StickNote component styles */
.stickNoteDiv {
  width: 4rem;
  height: 4rem;
  margin: 0.5rem 0.1rem;
  position: relative;
  cursor: pointer;
}
.stickNote {
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background-color: #FEFF9C;
}
.stickNoteShadow {
  position: absolute;
  left: 0;
  top: 0;
  height: 105%;
  width: 105%;
  opacity: 0.8;
  background: linear-gradient(to top, black 40%, transparent);
  z-index: -1;
  filter: blur(2px);
}
.stickNoteGlue {
  width: 100%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0.063);
}
.stickNoteContent {
  width: 80%;
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.stickNoteText {
  font-family: 'Permanent Marker', sans-serif;
  font-size: 0.8rem;
  margin: 5px auto;
  letter-spacing: -0.5px;
}
.stickNoteDiv.selected {
  transform: scale(1.1);
}
.stickNoteDiv:hover {
  transform: scale(1.1);
}
/*home navbar styles*/
.stickNoteDiv.home {
  width: 5.5rem;
  height: 6rem;
  margin: 0.5rem;
}
.stickNoteGlue.home {
  margin: 0px;
}
.stickNoteText.home {
  font-size: 1rem;
}
/**/
@media (min-width: 350px) {
  .stickNoteDiv {
    width: 4.6rem;
    height: 4.6rem;
    margin: 0.2rem;
  }
  .stickNoteText {
    font-size: 0.9rem;
    letter-spacing: 0px;
  }
}
@media (min-width: 750px) {
  .stickNoteDiv {
    width: 5.5rem;
    height: 5.5rem;
    margin: 0.4rem;
  }
  .stickNoteText {
    font-size: 1.1rem;
    letter-spacing: 0px;
  }
  .stickNoteDiv.home {
    width: 7rem;
    height: 7rem;
    margin: 0.5rem;
  }
}

@media (min-width: 1300px) {
  .stickNoteDiv {
    width: 6rem;
    height: 6rem;
    margin: 0.4rem;
  }
  .stickNoteText {
    font-size: 1rem;
    letter-spacing: 0px;
  }
}
@media (min-aspect-ratio: 3/2) and (max-height: 900px) and (min-width: 500px) {
  .stickNoteDiv {
    width: 4rem;
    height: 4rem;
    margin: 0.2rem;
  }
  .stickNoteText {
    font-size: 0.8rem;
    letter-spacing: 0px;
  }
}
