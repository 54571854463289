.panelDiv {
  max-width: 90vw;
  display: flex;
  flex-flow: row wrap;
  margin: 0rem;
  justify-content: center;
  align-items: center;
}

@media (min-width: 500px) {
.panelDiv {
  max-width: 90vw;
  display: flex;
  flex-flow: row wrap;
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  justify-content: center;
  align-items: center;
}
}