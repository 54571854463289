.homeHeader {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
}
.headerDiv {
  width: 100%;
}
