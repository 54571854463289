/* pinnedNote component styles */
.pinnedNoteDiv {
  width: 3.8rem;
  height: 3.8rem;
  margin: 0.2rem;
  position: relative;
  cursor: pointer;
}
.pinnedNote {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  background-color: #f5f5f5;
}
.pinnedNoteShadow {
  position: absolute;
  left: 0;
  top: 0;
  height: 105%;
  width: 105%;
  opacity: 0.8;
  background: linear-gradient(to top, black 40%, transparent);
  z-index: -1;
  filter: blur(2px);
}
.pinnedNoteContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}
.pinnedNoteTextDiv {
  width: 100%;
  height: 90%;
  display: flex;
  align-items: flex-start;
}
.pinnedNoteText {
  font-family: 'Permanent Marker', cursive;
  font-size: 0.8rem;
  margin: 0 auto;
  color: #000000;
}
.shakeNote:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}
  @keyframes shake {
    0% {
      transform: translate(2px, 2px) rotate(0deg);
    }
    20% {
      transform: translate(-1px, -1px) rotate(-2deg);
    }
    40% {
      transform: translate(2px, 2px) rotate(5deg);
    }
    60% {
      transform: translate(2px, 2px) rotate(5deg);
    }
    80% {
      transform: translate(2px, 2px) rotate(5deg);
    }
    100% {
      transform: translate(2px, 2px) rotate(0deg);
    }
  }
@media(min-width: 500px) {
  .pinnedNoteDiv {
    width: 4rem;
    height: 4rem;
    margin: 0.7rem 0.7rem 0 0;
  }
  .pinnedNoteText {
    font-size: 0.8rem;
  }
}
@media(min-width: 1200px) {
  .pinnedNoteDiv {
    width: 4.4rem;
    height: 4.4rem;
    margin: 1rem 1rem 0 0;
  }
  .pinnedNoteText {
    font-size: 1rem;
  }
}