
.header {
  width: 100%;
  z-index: 1;
  grid-row: 1;
  grid-column: 1;
}
.headerDiv {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px;
}