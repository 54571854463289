/* Sticker component styles */
.progressBarDiv {
  margin: 0;
  grid-column: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.progressBar {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 5.72rem;
  margin: 0;
  margin-left: 0.8rem;
}
.pinDiv {
  margin: 0px;
}
@media (min-width: 1200px) {
  .progressBarDiv {
    margin: 0 1rem;
  }
  .progressBar {
    width: 3.7rem;
  }
}