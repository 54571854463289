.resumeContent {
  grid-row: 2;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 900px) {
  .resumeContent {
    grid-row: 1;
    grid-column: 2;
    margin: 1rem;
  }
}
@media (min-width: 1200px) {
  .resumeContent {
    grid-row: 1;
    grid-column: 2;
    margin: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
