
.paperDiv {
  width: 90%;
  height: fit-content;
  margin: 0.5rem auto;
  position: relative;
}
.paper {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  z-index: 1;
  border-radius: 2px;
  box-shadow: 1px 2px 2px black;
}
.doublePinsDiv {
  width: 98%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}
.paperContentDiv {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-family: 'times new roman', sans-serif;
  text-align: center;
  font-size: 1rem;
  line-height: 1.2rem;
  padding: 0px 0px 1rem 0px;
}
.paperShadow {
  position: absolute;
  left: 0;
  top: 0;
  height: 98%;
  width: 101%;
  opacity: 0.8;
  background: linear-gradient(to top, black 60%, transparent);
  z-index: -1;
  filter: blur(2px);
}
.about.paperContentDiv {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.about.paperDiv {
  width: 90%;
  margin: 0.1rem auto;
}

@media (min-width: 900px) {
.project.paperDiv {
  width: 95%;
  min-width: 310px;
  margin: 0 0 1rem 0;
  break-inside: avoid-column;
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
}
}
@media (min-width: 1200px) {
.education.paperDiv {
  max-width: 100%;
}
}