/* Sticker component styles */
.pinSvg, .pinImage {
  margin: 0px;
  width: 1rem;
  height: 1.44rem;
  z-index: 2;
}
.notPinnedSvg, .notPinnedImage {
  /* margin: 0 0 0.14rem 0.71rem; */
  width: 0.3rem;
  height: 0.7rem;
  z-index: 2;
}
@media (min-width: 1200px) {
  .pinSvg, .pinImage {
    margin: 0px;
    width: 0.7rem;
    height: 1.2rem;
  }
  .notPinnedSvg, .notPinnedImage {
    margin: 0 0 0.14rem 0.3rem;
    width: 0.2rem;
    height: 0.6rem;
  }
}