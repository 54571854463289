.photoDiv {
  width: 9rem;
  display: flex;
  margin: 5rem auto 1rem auto;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  position: relative;
}
.polaroidDiv {
  width: 9rem;
  height: auto;
  position: relative;
  transform: rotate(-5deg);
}
.pinDiv {
  position: absolute;
  margin: 0rem 1.5rem 0 0;
  z-index: 1;
}
.photo {
  width: 100%;
  height: 100%;
}
.photoShadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 103%;
  height: 103%;
  opacity: 0.8;
  background: linear-gradient(to top, black 30%, transparent);
  z-index: -1;
  filter: blur(2px);
}
.photoDiv:hover {
  transform: scale(1.1);
  cursor: url(../../img/red-pin.png), pointer;
}
.photoDiv:hover > .pinDiv{
  margin: -0.1rem 1rem 0rem 0;
}
@media (min-width: 750px) {
  .photoDiv {
    width: 11rem;
    margin: 8rem auto 1.8rem auto;
  }
  .polaroidDiv {
    width: 11rem;
  }
}

@media (min-width: 1200px) {
  .pinDiv {
    margin: 0rem 1.5rem 0 0;
  }
  .photoDiv:hover > .pinDiv{
    margin: 0rem 1.3rem 0rem 0;
  }
}

@media (min-width: 1600px) {
  .photoDiv {
    width: 12rem;
    margin: 6rem auto 1.8rem auto;
  }
  .polaroidDiv {
    width: 12rem;
  }

}
@media (orientation: landscape) and (max-height: 500px){
  .photoDiv {
    width: 10rem;
    margin: 2rem auto 1.8rem auto;
  }
}