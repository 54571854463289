/* Sticker component styles */
.frameSvg {
  width: 80px;
  position: absolute;
  top: -23px;
  right: 0px;
}
.rotatedFrame{
  width: 80px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}